var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","hide-default-header":"","no-data-text":_vm.$t('message.appointment.notLoaded')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('v-icon',{attrs:{"color":item.icon.color}},[_vm._v(_vm._s(item.icon.name))])],1),(item.start === item.begin)?_c('td',[_vm._v(" "+_vm._s(item.beginDate)+" "),(item.endDate !== item.beginDate)?[_vm._v(" - "+_vm._s(item.endDate))]:_vm._e(),_vm._v(" von "+_vm._s(item.beginTime)+" - "+_vm._s(item.endTime)+" Uhr ")],2):_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.beginDate)),(item.endDate !== item.beginDate)?[_vm._v(" - "+_vm._s(item.endDate))]:_vm._e(),_vm._v(" von "+_vm._s(item.beginTime)+" - "+_vm._s(item.endTime)+" Uhr ")],2)]}}],null,true)},[_c('span',[_vm._v(" Abfahrt "+_vm._s(item.startTime)+" ")])])],1),_c('td',[(item.note)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"no-select"},on),[_vm._v(_vm._s(item.title))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])]):_c('span',{staticClass:"no-select"},[_vm._v(_vm._s(item.title))])],1),_c('td',[(item.conservatees.length === 1)?[_vm._v(" "+_vm._s(item.conservatees[0])+" ")]:_vm._e(),(item.conservatees.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.conservatees[0])+" ...")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.conservatees.join('<br />'))}})]):_vm._e()],2),_c('td',[(item.car)?[_vm._v(_vm._s(item.car.name))]:_vm._e()],2),(_vm.isAdmin() && _vm.$vuetify.breakpoint.mdAndUp)?_c('td',[_vm._v(" "+_vm._s(item.employee.name)+" ")]):_vm._e(),_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-icon',[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('cancel', item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }