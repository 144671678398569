








































































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import { AppointmentView } from '../types';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable }
})
export default class PluginTable extends AuthorizationMixin {
  @Prop({ type: Array, default: () => [] }) items!: AppointmentView[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
